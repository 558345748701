import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import IntObserver from 'components/IntObserver';
import classNames from 'classnames';
import { Picture } from 'components/Picture';
import AIMS_FLAVORS from 'lib/aimsFlavors';

import {
  article as ArticlePropType,
  newsletter as NewsletterPropType,
} from 'lib/CustomPropTypes';
import { stub as $t } from '@nbcnews/analytics-framework';
import { isBlogArticle } from 'lib/article';

import { getNewsletterData as getNewsletterDataAction } from 'redux/modules/newsletter';

import { SignupInput } from '../SignupInput';
import { pickSiteKey } from '../verticalLogic';
import { getInlineNewsletterConfig } from '../utils/getInlineNewsletterConfig';
import './styles.themed.scss';

$t('register', 'newsletter_subscribe');
$t('register', 'InlineNewsletterModule_Reached');

const block = 'nl-signup-inline';

/**
 * Maps state to props for the NewsletterSignupInline component.
 *
 * @param {object} state - The Redux state.
 * @param {object} state.shared - The shared state.
 * @param {object} state.article - The article state.
 * @param {object} state.newsletter - The newsletter state.
 * @param {object} ownProps - The component's own props.
 * @param {string} ownProps.vertical - The vertical.
 * @returns {object} The mapped props.
 */
const mapStateToProps = (
  {
    shared,
    article,
    newsletter,
  }, { vertical },
) => ({
  article: get(article, 'content[0]') || null,
  newsletterData: get(newsletter, ['newsletterData', 'Newsletters'], []),
  vertical: vertical || shared.vertical,
  isChromeless: shared.isChromeless,
});

/**
 * Maps dispatch to props for the NewsletterSignupInline component.
 *
 * @param {Function} dispatch - The Redux dispatch function.
 * @returns {object} The mapped dispatch functions.
 */
const mapActionToProps = (dispatch) => ({
  getNewsletterData: () => dispatch(getNewsletterDataAction()),
});

/**
 * NewsletterSignupInline component to render a newsletter signup form.
 *
 * @param {object} props - The properties object.
 * @param {object} props.article - The article object.
 * @param {Function} props.getNewsletterData - The function to get newsletter data.
 * @param {Array} props.newsletterData - The list of newsletter data.
 * @param {string} props.vertical - The vertical.
 * @param {boolean} props.isChromeless - Flag to indicate if the component is chromeless.
 * @param {boolean} [props.shouldThumbnailRender=true] - Flag to indicate if the thumbnail should render.
 * @returns {JSX.Element|null} The NewsletterSignupInline component or null if conditions are not met.
 */
const NewsletterSignupInline = ({
  article,
  getNewsletterData,
  newsletterData,
  vertical,
  isChromeless,
  shouldThumbnailRender = true,
}) => {
  useEffect(() => {
    /**
     * Fetches newsletter data and adjusts the iframe height.
     *
     * @returns {Promise<void>} A promise that resolves when the data is fetched and the height is adjusted.
     */
    const fetchData = async () => {
      await getNewsletterData();

      requestAnimationFrame(() => {
        // Newsletter embed heights are different based on the text set in the
        // configuration. To accommodate this dynamic height, send the height
        // of the body to the parent window
        // https://amp.dev/documentation/components/amp-iframe/#iframe-resizing
        window.parent.postMessage(
          {
            sentinel: 'amp',
            type: 'embed-size',
            height: document.body.scrollHeight,
          },
          '*',
        );
      });
    };

    fetchData();
  }, [getNewsletterData]);

  const handleObserverCallback = useCallback(([observer]) => {
    const { isIntersecting } = observer;
    if (isIntersecting) {
      $t('track', 'InlineNewsletterModule_Reached', true);
    }
  }, []);

  const onSubmit = useCallback((id, placement) => {
    if (id && placement) {
      $t('track', 'newsletter_subscribe', { id, placement });
    }
  }, []);

  // Do not show signup under these conditions:
  if (isChromeless || article.nativeAd || isBlogArticle(article)) {
    return null;
  }

  const sitekey = pickSiteKey(vertical);

  const newsletterConfig = getInlineNewsletterConfig(article.taxonomy, newsletterData);

  if (!newsletterConfig) {
    return null;
  }

  const newsletterTitle = get(newsletterConfig, 'newsletterTitle', null);
  const newsletterDescription = get(newsletterConfig, 'newsletterDescription', null);
  const newsletterTeaseImageUrl = get(newsletterConfig, 'newsletterTeaseImageUrl', null);
  const newsletterKey = get(newsletterConfig, 'newsletterKey', null);
  const newsletterSignupSource = get(newsletterConfig, 'newsletterSignupSource', null);

  const computedValues = {
    teaseImage: {
      url: {
        primary: newsletterTeaseImageUrl,
      },
    },
  };

  const shouldRenderThumbnailVariant = shouldThumbnailRender && newsletterTeaseImageUrl;

  return (
    <IntObserver
      callback={handleObserverCallback}
      threshold={0.25}
    >
      <div className={classNames(block, {
        [`${block}--with-picture`]: shouldRenderThumbnailVariant,
      })}
      >
        {shouldRenderThumbnailVariant && <div className={`${block}__header`}>Email Newsletters</div>}

        <div className={classNames(`${block}__container`, {
          [`${block}__container--with-picture`]: shouldRenderThumbnailVariant,
        })}
        >
          <div className={classNames(`${block}__metadata`, {
            [`${block}__metadata--with-picture`]: shouldRenderThumbnailVariant,
          })}
          >
            {shouldRenderThumbnailVariant && (
              <div className={`${block}__picture-container`}>
                <Picture
                  className={`${block}__picture`}
                  responsiveFlavors={{
                    s: AIMS_FLAVORS.FOCAL_460x460,
                    m: AIMS_FLAVORS.FOCAL_300x300,
                    xl: AIMS_FLAVORS.FOCAL_360x360,
                  }}
                  width={460}
                  height={460}
                  computedValues={computedValues}
                />
              </div>
            )}

            <div className={`${block}__details-container`}>
              <div className={`${block}__details`}>
                {newsletterTitle && (
                  <p className={classNames(`${block}__title`, {
                    [`${block}__title--with-picture`]: shouldRenderThumbnailVariant,
                  })}
                  >
                    {newsletterTitle}
                  </p>
                )}

                {newsletterDescription && (
                  <p className={classNames(`${block}__description`, {
                    [`${block}__description--with-picture`]: shouldRenderThumbnailVariant,
                  })}
                  >
                    {newsletterDescription}
                  </p>
                )}
              </div>
            </div>
          </div>

          <SignupInput
            grayscale={shouldRenderThumbnailVariant}
            newsletterId={newsletterKey}
            onSubmit={() => onSubmit(newsletterKey, newsletterSignupSource)}
            signupSource={newsletterSignupSource}
            sitekey={sitekey}
            vertical={vertical}
          />
        </div>
      </div>
    </IntObserver>
  );
};

NewsletterSignupInline.propTypes = {
  article: ArticlePropType.isRequired,
  getNewsletterData: PropTypes.func,
  newsletterData: PropTypes.arrayOf(NewsletterPropType),
  vertical: PropTypes.string.isRequired,
  isChromeless: PropTypes.bool.isRequired,
  shouldThumbnailRender: PropTypes.bool,
};

NewsletterSignupInline.defaultProps = {
  getNewsletterData: Function.prototype,
  newsletterData: [],
  shouldThumbnailRender: true,
};

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapActionToProps)(NewsletterSignupInline);
